import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Pusher.logToConsole = true

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true
});
window.Echo.channel('messages')
    .listen('.ChatMessageCreated', (e) => {
        console.log('New message received:', e.message);
        // Add logic to update the UI with the new message
    });
