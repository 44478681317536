import './bootstrap.js'
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import Clipboard from '@ryangjchandler/alpine-clipboard'

Alpine.plugin(Clipboard)

Livewire.start()

function initSmoothScroll() {
    new SmoothScroll('a[href*="#"]', {
        offset: function(anchor, toggle) {
            const header = document.getElementById('main-header');
            const banner = document.getElementById('beta-banner');
            const headerHeight = header?.offsetHeight || 0;

            // Check if banner exists and is visible using Alpine's x-show state
            const bannerVisible = banner && !banner.hasAttribute('hidden');
            const bannerHeight = bannerVisible ? (banner?.offsetHeight || 0) : 0;

            return headerHeight + bannerHeight;
        },
    });
}

// Initialize on first load
document.addEventListener('DOMContentLoaded', initSmoothScroll);

// Initialize after Livewire navigation
document.addEventListener('livewire:navigated', initSmoothScroll);

// Add this at the global scope
window.initMap = function() {
    // If you have any map initialization code, put it here
};
